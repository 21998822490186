.e-logo-div {
  cursor: pointer;
  background-color: white;
  border: 1.5px solid white;
  height: 5px;
  width: 65px;
  box-sizing: border-box;
}
.e-logo-div:hover {
  border: 1.5px solid #006AFF;
}
.App {
  text-align: center;
}
iframe {
  position: static !important;
  width: 0 !important;
  height: 0 !important;
  z-index: -999 !important;
}
#root {
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

i.icon.hidden {
  display: none;
}

#playButton {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: scale(5, 5);
}

.fullscreen {
  height: 100%;
  background-color: black;
}

video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
  /* position: absolute;
  top: 0;
  left: 0; */
}
.video-wrapper {
  width: fit-content;
  max-width: 100%;
  max-height: 100%;
  /* padding-top: 56.25%; */
  /* height: fit-content; */
  position: relative;
  margin: 0 auto;
}

.logobacking {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: rgb(250, 250, 250);
  border-radius: 50% 50%;
  width: 160px;
  height: 160px;
}

.play {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 64px;
  height: 64px;
  fill: black;
}

.stopped {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 44px;
  height: 44px;
  fill: #9e9e9e;
}

.logo {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 128px;
  height: 128px;
}
.logo path {
  stroke-dasharray: 315;
  stroke-dashoffset: 315;
  animation: dash 1.5s alternate infinite;
  stroke: #9e9e9e;
  stroke: width 1.2px;
  fill: white;
}

.logo g {
  animation: fill-in 1.5s 1.5s alternate infinite;
  fill-opacity: 1;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill-in {
  to {
    fill-opacity: 1;
  }
}

.hidden {
  display: none !important;
}

/* Right Side UI */
button {
  cursor: pointer;
}
.no-pointer-events {
  pointer-events: none;
}
.important-pointer-events {
  pointer-events: auto !important;
}
.tabs {
  position: relative;
  height: calc(100% - 146px);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
}
.left_tab {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  border-radius: 27px 27px 27px 27px;
  overflow: hidden;
  backdrop-filter: blur(82px);
  background-color: rgba(255, 255, 255, 0.05);
}

.tab_image {
  visibility: hidden;
}
h1,h2,h3,h4 {
  margin: 0;
  padding: 0;
  font-family: 'Space Grotesk', sans-serif;
}
.grid_icon_btn {
  height: 15px;
}
.k_btn {
  background-color: transparent;
  outline: none;
  border: none;
  color: white;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}
.item_menu {
  position: relative;
  /* margin-top: 10%; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  font-family: 'Space Grotesk', sans-serif;
}
.filter-popup {
  position: absolute;
  top: 80%;
  z-index: 7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(82px);
  border-radius: 24px;
  gap: 12px;
  max-width: 100%;
}
.sort-popup {
  position: absolute;
  width: 120%;
  top: 120%;
  left: -20%;
  z-index: 7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  background: rgba(34, 38, 44, 0.9);
  backdrop-filter: blur(2px);
  border-radius: 24px;
  gap: 12px;
}
.filter-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  max-width: 100%;
  flex-wrap: wrap;
}
.filter-button {
  box-sizing: border-box;
  border: 1px solid #FFFFFF;
  backdrop-filter: blur(20px);
  border-radius: 50px;
  padding: 7px 15px;
  background-color: transparent;
  color: white;
  cursor: pointer;
}
.filter-button.type {
  padding: 6px 13px;
}
.filter-button.active {
  border: 1px solid #006AFF;
}
.filter-button:hover {
  border: 1px solid #006AFF;
}
.sort-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.sort-button:hover {
  color: #006AFF;
}
.sortCheckMark {
  margin-left: 10px;
  width: 12px;
  margin-bottom: 1px;
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
}
.item_sub_menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu_icon {
  width: 12px;
  margin-right: 5px;
}
.menu_sub_icon {
  width: 12px;
}
.k_btn_styled {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1.5px solid rgba(255, 255, 255, 0.005);
  padding: 10px 12px;
  border-radius: 50px;
  font-family: 'Space Grotesk', sans-serif;
  display: flex;
  align-items: center;
}
.k_btn_styled:hover {
  border: 1.5px solid #006AFF;
}
.k_btn_styled_2 {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1.5px solid rgba(255, 255, 255, 0.005);
  /* padding: 8px 12px; */
  border-radius: 50px;
  font-family: 'Space Grotesk', sans-serif;
  display: flex;
  min-height: 37.7px;
  min-width: 37.7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.k_btn_styled_2:hover {
  border: 1.5px solid #006AFF;
}
.left_tab_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 27%;
  padding-left: 3%;
  margin-top: 2%;
  cursor: pointer;
  z-index: 5;
  font-family: 'Space Grotesk', sans-serif;
}
.left_tab_header:hover .left_tab_header_total {
  color: #006AFF !important;
}
.left_tab_header h2 {
  margin: 0;
}
.left_tab_header svg text{
  font-family: 'Space Grotesk', sans-serif;
}
.left_tab_header_total {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  font-size: 11px;
  padding: 2px 5px;
  font-family: 'Space Grotesk', sans-serif;
  color: #006AFF;
}
.wrapper_left {
  padding: 15px;
  transition: opacity 1s;
  height: 100%;
}
.nav_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.nav_wrapper h2 {
  font-size: 16px;
  font-family: 'Space Grotesk', sans-serif;
}
.scroll_box {
  position: relative;
  display: grid;
  gap: 10px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  max-height: calc(100% - 115px);
  padding-bottom: 30px;
}
.scroll_box_large_grid {
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: max-content;
}
.scroll_box_small_grid {
  grid-template-columns: 1fr 1fr;
}
.scroll_box::-webkit-scrollbar {
  display: none;
}
.item_wrapper {
  display: block;
  position: relative;
  max-height: fit-content;
  min-height: max-content;
}
.scroll_box_item {
  display: block;
  position: relative;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  border: 3px solid transparent;
  height: 100%;
}
.scroll_box_item:hover {
  border: 3px solid #006AFF;
}
.scroll_box_item.active {
  border: 3px solid #006AFF;
}
.wallet-button:hover {
  border-color: #006AFF;
}
.scroll_box_item_overlay {
  pointer-events: none;
  border-radius: 24px;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color:  rgba(26, 26, 26, 0.393); */
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: 'Space Grotesk', sans-serif;
  padding: 5%;
  text-align: center;
}
.scroll_box_item_overlay_message.active {
  display: none;
}
.scroll_box_item_overlay_message {
  opacity: 0;
}
.item_wrapper:hover > .scroll_box_item_overlay > .scroll_box_item_overlay_message{
  opacity: 1;
}
.nonGreyScaleImage {
  filter: none !important;
  opacity: 1 !important;
}
.displayNone {
  display: none;
}
.item_image {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
  cursor: pointer;
}
.fullscreen_icon {
  margin: 0;
  width: 12px;
}
.fullscreen_btn {
  float: right;
  background-color: rgba(255, 255, 255, 0.05);
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-right: 12px;
}
.item_nav_wrapper {
  font-family: 'Space Grotesk', sans-serif;
  position: absolute;
  top: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.item_nav_wrapper p {
  width: fit-content;
}
.edition {
  font-family: 'Space Grotesk', sans-serif;
  margin: 0;
  margin-left: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  font-size: 10px;
  line-height: 13px;
  height: 27px;
  min-width: 27px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.price_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
}
.eth_icon {
  width: 20px;
  margin-right: 5px;
  margin-left: 12px;
}
.eth_price {
  margin: 0;
  font-family: 'Space Grotesk', sans-serif;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 14px;
}
.item_title {
  font-family: 'Space Grotesk', sans-serif;
  margin-left: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}
.item_overlay_bg {
  position: relative;
  scale: 1.1;
  position: absolute;
  top: 10%;
  right: 5%;
  width: 100%;
  height: 90%;
  background-size: cover;
  background-image: url('./images/ConfigRoughCompSansPortals_TAB.png');
  background-repeat: no-repeat;
  border-radius: 27px;
  z-index: 7;
  transition: opacity 500ms;
}
.item_overlay {
  position: relative;
  border-radius: 36px;
  z-index: 6;
  /* margin-top: -40px; */
  height: 100%;
  width: 100%;
  /* backdrop-filter: blur(82px); */
  background-image: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5),transparent);
}
.overlay_tab_title {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 500;
  font-size: 1.3vw;
  line-height: 1.3vw;
  margin: 0;
}
.overlay_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 57%;
  position: absolute;
  top: 2.5%;
  left: 2.7%;
}
.overlay_nav_2 {
  margin: 0;
  position: absolute;
  top: 4.7vw;
  right: 1.5vw;
  text-align: right;
}

.overlay_nav_2 h4 {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1.4vw;
  letter-spacing: 0.05em;
}
.exit_icon {
  width: 8px;
}
.k_btn_styled_3 {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 5px 9px;
  border-radius: 50px;
}
.edition_overlay {
  margin: 0;
  margin-left: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  padding: 5px;
  font-size: 14px;
  line-height: 13px;
  width: fit-content;
  margin-bottom: 10px;
  font-family: 'Space Grotesk', sans-serif;
}
.backdropFilterInside {
  position: absolute;
  top: 8%;
  right: 0;
  bottom: 5%;
  left: 0;
  margin: auto;
  z-index: -1;
}
.info_wrapper {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.item_title_overlay {
  font-size: 2.1vw;
  font-weight: 500;
  margin-left: 20px;
  margin-bottom: 15px;
  margin-top: 0px;
  font-family: 'Space Grotesk', sans-serif;
}
.eth_icon_overlay {
  width: 40px;
  margin-right: 14px;
}
.eth_price_overlay {
  margin: 0;
  font-size: 22px;
  line-height: 22px;
  font-family: 'Space Grotesk', sans-serif;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.5);
}
.item_image_overlay {
  width: 65%;
  margin: 0 auto;
  margin-top: 0px;
  display: block;
  font-family: 'Space Grotesk', sans-serif;
}
.price_wrapper_overlay {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.price_subwrapper_overlay {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.price_subflex_overlay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.price_subflex_overlay._2 {
  margin-top: 5px;
}
.UE_P {
  margin: 0;
  margin-right: 5px;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  line-height: 120%;
  color: rgba(255, 255, 255, 0.5);
}
.UE_Img {
  width: 34%;
}
.try_on_btn {
  background: #FFFFFF;
  border-radius: 142.5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  font-family: 'Space Grotesk', sans-serif;
  color: #0D0D0D;
  padding: 10px 12px;
  font-weight: 500;
  box-sizing: border-box;
  white-space: nowrap;
  align-self: flex-end;
}
.try_on_btn:hover {
  border: 2px solid rgba(0, 106, 255, 0.3);
}
.tabsWrapper {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40%;
  padding-top: 20px;
  padding-right: 25px;
  /* max-height: 100vh; */
}
/* Cart UI */
.cart-wrapper {
  width: 100%;
  position: relative;
}
.price_wrapper_cart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.cart_item {
  display: grid;
  grid-template-columns:60px auto auto 50px ;
  /* flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  margin-bottom: 10px;
}
.cart-item-title {
  display: flex;
  align-items: center;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 1.1vw;
  color: white;
  margin: 0;
}
.remove_cart_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-left: 1px;
  margin-bottom: 1px;
  margin: auto;
}
.remove_cart_item_image {
  width: 60%;
  margin-left: 0.9px;
}
.item_image_cart {
  height: 50px;
}
.eth_price_cart {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1vw;
  margin-left: 1px;
  color: rgba(255, 255, 255, 0.5);
}
.eth_icon_cart {
  width: 20px;
  margin-right: 2px;
}
.cart-item-overlay {
  position: absolute;
  bottom: 120%;
  z-index: 6;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(46px);
  border-radius: 24px;
  padding: 24px;
}
.cart-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(82px);
}
.cart-view-button {
  padding: 11px 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 40px;
  width: 48px;
  height: 48px;
  position: relative;
  border: 1.5px solid rgba(255, 255, 255, 0.005);
  outline: none;
  cursor: pointer;
}
.cart-view-button:hover {
  border: 1.5px solid #006AFF;
}
.cart-count {
  position: absolute;
  top: 0px;
  right: -3px;
  width: 16px;
  height: 16px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  background: #006AFF;
  border-radius: 50%;
  color: white;
}
.cart-icon {
  width: 18px;
}
.cart-mint-button {
  padding: 10px 16px;
  background: #006AFF;
  border: 2px solid #006AFF;
  border-radius: 24px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: white;
  cursor: pointer;
}
.cart-mint-button:hover {
  border: 2px solid #0B2D5B;
}
.cart-price-title {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 1.7vw;
  color: white;
}
/* Left Side UI */
.leftSideUI-wrapper {
  width: 16%;
  height: 100%;
  /* max-height: 100vh; */
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
  padding-left: 20px;
  padding-bottom: 25px;
  padding-top: 20px;
}
.description-popup-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.description-popup {
  display: none;
  border-radius: 24px;
  position: absolute;
  bottom: 3%;
  left: 100px;
  width: 160%;
  max-width: 420px;
}
.description-popup-overlay {
  border-radius: 24px;
  padding: 8%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.description-popup h4 {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 5px;
}
.description-popup p {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Space Grotesk', sans-serif;
}

.nav_popup {
  pointer-events: none;
  display: none;
  border-radius: 24px;
  position: absolute;
  top: 0%;
  left: 0px;
  width: 160%;
  width: 100%;
  z-index: 9;
}
.nav_popup_overlay {
  border-radius: 24px;
  padding: 8%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.nav_popup h4 {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 5px;
}
.nav_popup p {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Space Grotesk', sans-serif;
}

.description-popup.below {
  background-image: url('./images/Below.jpeg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.description-popup.surface {
  background-image: url('./images/Surface.jpeg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.description-popup.above {
  background-image: url('./images/Above.jpeg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.nav_popup.below {
  background-image: url('./images/Below.jpeg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.nav_popup.surface {
  background-image: url('./images/Surface.jpeg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.nav_popup.above {
  background-image: url('./images/Above.jpeg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.e-logo-div,.below:hover ~ #description-popup-below{
  display: block !important;
}
.e-logo-div,.surface:hover ~ #description-popup-surface{
  display: block !important;
}
.e-logo-div,.above:hover ~ #description-popup-above{
  display: block !important;
}

.backdropFilterInside video {
  width: 95%;
  margin: auto;
  display: block;
  /* position: relative;
  top: -12%;
  z-index: -1; */
}
.wallet-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(46px);
  padding: 12px 16px 12px 12px;
  border-radius: 40px;
}
.wallet-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.wallet-button {
  padding: 0px;
  border-radius: 50%;
  border: 3px solid #51565C;
  background-color: transparent;
  margin-right: 4%;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wallet-button-icon {
  width: 14px;
}
.wallet-profile-icon {
  width: 30px;
  margin-right: 10px;
  border-radius: 50%;
}
.wallet-info {
  padding-left: 4%;
}
.divider {
  border-left: 1px solid #51565C;
  padding-top: 25px;
  padding-bottom: 5px;
}
.ellipse-button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin-bottom: 10px;
}
.ellipse-button.last {
  margin-bottom: 0px;
}
.ellipse-image {
  width: 100%;
}
.wallet-account-number {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Space Grotesk', sans-serif;
}
.post_overlay {
  position: absolute;
  top: 0;
  background: rgba(13, 13, 13, 0.7);
  backdrop-filter: blur(82px);
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center; 
}
.post-inner_overlay {
  margin: 0 auto;
  width: fit-content;
  /* max-width: 50%; */
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(82px);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 40px;
  gap: 40px;
  color: white;
  max-height: 100%;
}
.post-inner_overlay h2 {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.03em;
  white-space: pre-line;
}
.post-inner_overlay h3 {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.03em;
  color: rgba(255, 255, 255, 0.6);
  white-space: pre-line;
}
.post-inner_overlay h4 {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFC300;
  text-transform: uppercase;
  white-space: pre-line;
}
.post-inner_image {
  width: 45%;
}
.post-inner_content {
  text-align: center;
}
.post-inner_btn1 {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  width: fit-content;
  padding: 16px;
  cursor: pointer;
  background: #006AFF;
  border: 2px solid rgba(0, 106, 255, 0.3);
  border-radius: 999px;
  color: white;
  white-space: nowrap;
}
.post-inner_btn2 {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  background: none;
  border: none;
  outline: none;
  color: white;
}
.filter-row._1 {
  margin-bottom: 15px;
}
.filter-row._2 {
  margin-bottom: 5px;
}
.quantity_span {
  margin-left: 5px;
  color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
}

/* @media screen and (max-width: 1150px) {
  
  
} */

@media screen and (max-width: 1100px) {
  .nav_wrapper h2 {
    font-size: 14px;
  }
  .price_wrapper_overlay {
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .try_on_btn {
    font-size: 14px;
    line-height: 16px;
    padding: 7px 10px;
  }
  .backdropFilterInside {
    bottom: 10%;
  }
  .eth_price_overlay {
    font-size: 18px;
    line-height: 18px;
  }
  .eth_icon_overlay {
    width: 32px;
  }
}
@media screen and (max-width: 1360px) {
  .filter-button {
    font-size: 12px;
    padding: 4px 7px;
  }
  .filter-button.type {
    font-size: 12px;
    padding: 4px 7px;
  }
  .UE_P {
    font-size: 12px;
  }
}
@media screen and (max-width: 1450px) {
  .new_sub_menu {
    flex-direction: column-reverse;
    gap: 5px;
  }
  .sort-popup {
    left: unset;
    right: 10px;
    width: 130px;
  }
}
@media screen and (max-width: 1300px) {
  .left_tab_header, .center_tab_header, .right_tab_header {
    margin-top: 1%;
  }
  .item_overlay_bg {
    height: 100%;
  }
  /* .item_image {
    width: 60%;
    margin-top: 20px;
  } */
  /* .cart-price-title {
    font-size: 24px;
  } */
  .item_title {
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 14px;
  }
  .eth_price {
    font-size: 14px;
  }
  /* .cart-menu {
    padding: 15px;
  } */
  /* .cart-item-overlay {
    top: -229%;
  } */
  /* .overlay_tab_title {
    font-size: 12px;
  } */
  .edition_overlay {
    font-size: 12px;
  }
  .item_title_overlay {
    font-size: 1.9vw;
  }
}
@media screen and (max-width: 1100px) {
  /* .cart-price-title {
    font-size: 14px;
  } */
  /* .overlay_tab_title {
    font-size: 10px;
  } */
  /* .cart-menu {
    padding: 12px;
  } */
  .cart-mint-button {
    font-size: 12px;
  }
  .item_title_overlay {
    margin-bottom: 5px;
    font-size: 18px;
  }
}
@media screen and (max-width: 1080px) {
  /* .cart-item-overlay {
    top: -160%;
  } */
}
@media screen and (max-width: 1575px) {
  .wallet-profile-icon {
    width: 27px
  }
  .wallet-button {
    padding: 6px 8px;
    padding-right: 9px;
    margin-right: 2%;
    cursor: pointer;
  }
  .wallet-button-icon {
    width: 10px;
  }
  .wallet-info {
    padding-left: 2%;
  }
  .wallet-wrapper {
    padding: 10px 12px 10px 10px;
  }
  .wallet-account-number {
    font-size: 12px;
  }
}
@media screen and (max-width: 1300px) {
  .wallet-wrapper {
    padding: 6px 8px 6px 6px;
  }
}

.overflow-visible {
  overflow: visible !important;
}